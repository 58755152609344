import React, { Component } from "react";
import styled from "styled-components";
import * as ReactScroll from "react-scroll";
import Link from "../Link";
import LangSwitcher from "../LangSwitcher";
import * as L from "../../Utils/Lang";

class Footer extends Component {
  render() {
    let { extraStyle = "", pathname } = this.props;

    if (
      pathname === "/cart/" ||
      pathname === "/checkout/" ||
      pathname === "/order/"
    ) {
      return null;
    }

    return (
      <Wrapper extraStyle={extraStyle}>
        <Info>
          <Content main>
            <div className="company-name"></div>
            {/* <Logo img="images/small-logo.png" /> */}
            <Logo img="images/tel-u-logo.png" />
            {/* <div className="contact">info@xxxxxmail.com</div> */}
            <div className="contact">{L.s("companyName")}</div>
            <div className="contact">{L.s("address")}</div>
          </Content>

          <Content>
            {/* <div className="row-links">
              <div>
                <Link
                  to="#"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: "underline",
                    color: "#ffffff"
                  }}
                >
                  FAQ
                </Link>
              </div>
              <div>
                <Link
                  to="#"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: "underline",
                    color: "#ffffff"
                  }}
                >
                  隱私權政策
                </Link>
              </div>
              <div>
                <Link
                  to="#"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: "underline",
                    color: "#ffffff"
                  }}
                >
                  使用者條款
                </Link>
              </div>
            </div> */}

            <div className="menu">
              <Link
                to="/"
                extraStyle={{
                  textDecoration: "none",
                  color: "#ffffff"
                }}
              >
                <h3>{L.s("home")}</h3>
              </Link>

              <div>
                <Link
                  to="#home-1"
                  extraStyle={{
                    textDecoration: "none",
                    color: "#ffffff"
                  }}
                >
                  eSIM
                </Link>
              </div>
              <div>
                <Link
                  to="#home-2"
                  extraStyle={{
                    textDecoration: "none",
                    color: "#ffffff"
                  }}
                >
                  {L.s("howOperate")}
                </Link>
              </div>
              <div>
                <Link
                  to="#home-3"
                  extraStyle={{
                    textDecoration: "none",
                    color: "#ffffff"
                  }}
                >
                  {L.s("whyM2m")}
                </Link>
              </div>
            </div>
          </Content>
          <Content>
            <div className="menu">
              <Link
                to="/product"
                extraStyle={{
                  textDecoration: "none",
                  color: "#ffffff"
                }}
              >
                <h3 style={{ maxWidth: 150 }}>{L.s("productsAndServices")}</h3>
              </Link>

              <div>
                <Link
                  to="product#product-1"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: "none",
                    color: "#ffffff"
                  }}
                >
                  {L.s("advantage")}
                </Link>
              </div>
            </div>
          </Content>

          <Content>
            <div className="menu">
              <Link
                to="/about-us"
                extraStyle={{
                  textDecoration: "none",
                  color: "#ffffff"
                }}
              >
                <h3>{L.s("aboutUs")}</h3>
              </Link>

              <div>
                <Link
                  to="/about-us#about-us-1"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: "none",
                    color: "#ffffff"
                  }}
                >
                  {L.s("ourTech")}
                </Link>
              </div>
              <div>
                <Link
                  to="/about-us#about-us-2"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: "none",
                    color: "#ffffff"
                  }}
                >
                  {L.s("contactUs")}
                </Link>
              </div>
              <div>
                <Link
                  to="/about-us#about-us-3"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: "none",
                    color: "#ffffff"
                  }}
                >
                  {L.s("whyMDC")}
                </Link>
              </div>
            </div>
          </Content>
          <Content>
            <LangSwitcher extraCss="margin-top: 30px;" />
          </Content>
        </Info>
        <BottomInfo>
          <Content main>
            Copyright © 2019 TEL-U Limited. All rights reserved.
          </Content>

          {/* <Content>
            <div className="bottom-links">
              <div>
                <Link
                  to="#"
                  extraStyle={{
                    marginTop: 20,
                    marginLeft: 40,
                    color: "#ffffff",
                    textDecoration: "none"
                  }}
                >
                  常見問題
                </Link>
              </div>
              <div>
                <Link
                  to="#"
                  extraStyle={{
                    color: "#ffffff",
                    marginLeft: 40,
                    textDecoration: "none"
                  }}
                >
                  隱私權政策
                </Link>
              </div>
              <div>
                <Link
                  to="#"
                  extraStyle={{
                    marginLeft: 40,
                    color: "#ffffff",
                    textDecoration: "none"
                  }}
                >
                  使用者條款
                </Link>
              </div>
            </div>
          </Content> */}
        </BottomInfo>
      </Wrapper>
    );
  }
}

let Wrapper = styled.div`
  background: linear-gradient(to right, #0d3369, #418db9);
  width: 100vw;
  padding: 40px 10px 80px 10px;
  color: #ffffff;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
  ${props => props.extraStyle};
`;

let Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
`;

let BottomInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 30px;
  font-size: 15px;
  padding-top: 20px;
  border-top: 1px solid #eee;

  @media screen and (max-width: 700px) {
    flex-wrap: wrap;
  }
`;

let Content = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-grow: ${props => (props.main ? 1 : 0)};
  min-height: ${props => (props.main ? "50px" : "100px")};

  & > .contact {
    margin-top: 12px;
  }

  & > .title {
    font-size: 15px;
    margin-bottom: 10px;
  }

  & > .menu {
    min-width: 180px;
    & > div {
      margin-top: 12px;
    }
  }

  & .bottom-links {
    display: flex;
    /* width: 100%; */
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  @media screen and (max-width: 700px) {
    margin-top: 20px;
    width: 100%;
    min-width: 200px;

    & .bottom-links {
      flex-direction: column;
    }
  }
`;

let Logo = styled.div`
  width: 110px;
  height: 25px;
  background-image:url("${props => props.img}");
  background-repeat: no-repeat;
  background-size:contain;
`;

let Socials = styled.div`
  display: flex;
  flex-direction: row;
`;

let SocialLink = styled.div`
  width: 40px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("${props => props.img}")
`;

export default Footer;
