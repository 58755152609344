const Settings = {
    env: "stg",
    webUrl: "https://mdc.revtel2.com",
    apiUrl: "https://wantcard-api-stg.revtel2.com",
    mediaHost: "https://dvkvars7ubgms.cloudfront.net",
    s3Host: "https://s3-ap-northeast-1.amazonaws.com/stg.hecafe.com.tw",
    debug: true,
    facebookAppId: "1276459929127208",
    googleClientId: "115128487422-bs5m8q90u3g4ata8lb9ujorusgpqhefj.apps.googleusercontent.com",
    googleAnalyticsId: "UA-127064537-1",
    userType: "aws",
    slsApi: {
      host: "https://907nonhjeg.execute-api.ap-northeast-1.amazonaws.com/stg",
    },
    aws: {
      tableOrder: "stg.hecafe.com.tw.order",
      tableStoreOrder: "stg.hecafe.com.tw.store-order"
    },
};

module.exports = Settings;

