import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Selectors from "../../Selectors";
import Link from "../Link";
import * as Widget from "../Widget";
import * as Icon from "../Icon";
import AppConfig from "../../Config";
import * as L from "../../Utils/Lang";

const {
  background,
  altBackground,
  textColor,
  textShadow,
  breakpoint
} = AppConfig["Components/Navbar"];
// const height = AppConfig.global.navbarHeight;

class Navbar extends React.Component {
  state = {
    openMenu: false
  };

  render() {
    let { passBreakpoint } = this.props;

    return (
      <Style.Wrapper passBreakpoint={passBreakpoint}>
        <Style.Content>
          <Link to="/" className="site-title">
            {/* <Style.Logo img="images/logo.png" /> */}
            <Style.Logo img="images/tel-u-logo.png" />
          </Link>

          {this._smallScreenNavBar()}
          {this._largeScreenNavBar()}
        </Style.Content>
      </Style.Wrapper>
    );
  }

  /*
    中文nav: 產品與服務(=product)/關於我們/為什麼選擇MDC?
    en nav: product / service / about us / why TEL-U / contact us（點了跳service填資訊區塊）
  */
  _smallScreenNavBar() {
    let { Lang } = this.props;
    let navItems = [];
    if (Lang == "en") {
      navItems = [
        { children: `${L.s("products")}`, url: "/product" },
        { children: `${L.s("services")}`, url: "/service" },
        { children: `${L.s("aboutUs")}`, url: "/about-us" },
        { children: `${L.s("whyMDC")}`, url: "/about-us#about-us-3" },
        { children: `${L.s("contactUs")}`, url: "/about-us#about-us-3" }
      ];
    } else {
      navItems = [
        { children: `${L.s("productsAndServices")}`, url: "/product" },
        { children: `${L.s("aboutUs")}`, url: "/about-us" },
        { children: `${L.s("whyMDC")}`, url: "/about-us#about-us-3" }
      ];
    }

    const toggleMenuState = () =>
      this.setState({ openMenu: !this.state.openMenu });
    return (
      <Style.SmallScreenWrapper breakpoint={breakpoint}>
        <Widget.IconButton onClick={toggleMenuState}>
          <Icon.Apps color="white" />
        </Widget.IconButton>

        <Style.SmallScreenMenu
          open={this.state.openMenu}
          onClick={toggleMenuState}
        >
          <div className="container">
            {[
              { children: <Icon.Home color="white" />, url: "/" },
              ...navItems
            ].map(({ children, url }, idx) => (
              <Link to={url} key={idx} className="nav-item">
                {children}
              </Link>
            ))}
          </div>
        </Style.SmallScreenMenu>
      </Style.SmallScreenWrapper>
    );
  }

  _largeScreenNavBar() {
    let { Lang } = this.props;
    let navItems = [];
    if (Lang == "en") {
      navItems = [
        { children: `${L.s("products")}`, url: "/product" },
        { children: `${L.s("services")}`, url: "/service" },
        { children: `${L.s("aboutUs")}`, url: "/about-us" },
        { children: `${L.s("whyMDC")}`, url: "/about-us#about-us-3" },
        { children: `${L.s("contactUs")}`, url: "/service#contact-us" }
      ];
    } else {
      navItems = [
        { children: `${L.s("productsAndServices")}`, url: "/product" },
        { children: `${L.s("aboutUs")}`, url: "/about-us" },
        { children: `${L.s("whyMDC")}`, url: "/about-us#about-us-3" }
      ];
    }
    return (
      <Style.LargeScreenWrapper breakpoint={breakpoint}>
        {navItems.map(({ children, url }, idx) => (
          <Link to={url} key={idx} className="nav-item">
            {children}
          </Link>
        ))}
      </Style.LargeScreenWrapper>
    );
  }
}

const Style = {
  Wrapper: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 10px 20px;
    height: 150px;
    z-index: 10;
    background: linear-gradient(black, transparent);
    transition: background 200ms ease-in-out;
  `,

  Logo: styled.div`
      width: 140px;
      height: 40px;
      background-image: url("${props => props.img}");
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;
      top: -3px;
  `,

  Content: styled.div`
    margin: 0 auto;
    max-width: 1024px;
    height: 100%;
    display: flex;
    align-items: center;

    & > .site-title {
      color: ${textColor};
      /* text-shadow: ${textShadow}; */
      text-decoration: none;
      font-size: 30px;
    }
  `,

  LargeScreenWrapper: styled.div`
    @media screen and (max-width: ${props => props.breakpoint || 768}px) {
      display: none;
    }

    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    & > .nav-item {
      margin-right: 50px;
      color: #ffffff;
      font-size: 14px;
      letter-spacing: 4px;
      text-decoration: none;
      display: flex;
      align-items: center;
    }

    & > .nav-item :last-child {
      margin-right: 0px;
    }
  `,

  SmallScreenWrapper: styled.div`
    @media screen and (min-width: ${props => props.breakpoint || 768}px) {
      display: none;
    }

    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  `,

  SmallScreenMenu: styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: ${props => (props.open ? "0" : "-100vw")};
    background-color: ${props =>
      props.open ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0)"};
    transition: background-color 250ms linear,
      left 0ms linear ${props => (props.open ? "0ms" : "250ms")};
    z-index: 5;

    & > .container {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding-top: 30px;

      & > .nav-item {
        color: ${textColor};
        height: 50px;
        text-decoration: none;
      }
    }
  `
};

export default connect((state, ownProps) => ({
  Lang: Selectors.getLang(state)
}))(Navbar);
