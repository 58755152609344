const AppConfig = {
  global: {
    themeColor: "#0075c1",
    navbarHeight: 60,
    navbarCoverContent: false,
    supportedPayType: ["ecpay", "offline"]
  },

  data: {
    localProductSearchFields: ["name", "description"]
  },

  "Components/Footer": {},

  "Components/Navbar": {
    background: "#e6e6e6",
    altBackground: "#e6e6e6",
    textColor: "#fff",
    textShadow: "2px 2px #ccc",
    breakpoint: 800
  },

  "Components/Lightbox": {
    css: ``,
    thumbnailCss: `
      width: 70px;
      height: 70px;
    `
  },

  "Components/OrderList": {
    css: ``
  },

  "Components/AddToCartButton": {
    css: ``
  },

  "Components/FilterPanel": {
    breakPointTablet: 1024
  },

  "Component/SortingGroup": {
    color: "gray",
    articlesOptions: [
      { label: "發表日期", value: "created" },
      { label: "更新日期(新 → 舊)", value: "-updated" },
      { label: "更新日期(舊 → 新)", value: "+updated" }
    ],
    productsOptions: [
      { label: "上市日期", value: "created" },
      { label: "優惠價(高 → 低)", value: "-price" },
      { label: "優惠價(低 → 高)", value: "+price" },
      { label: "更新日期(新 → 舊)", value: "-updated" },
      { label: "更新日期(舊 → 新)", value: "+updated" }
    ],
    css: ``
  },

  "Component/SortOption": {
    color: "gray",
    css: ``
  },

  "Templates/LandingPage": {
    css: `

    `
  },

  "Templates/StoreDetailPage": {
    css: `
    `
  },

  "Templates/OrderDetailPage": {
    css: ``
  },

  "Templates/CartPage": {
    css: ``
  },

  "Templates/ProductListingPage": {
    layoutType: "grid",
    itemLimit: 12,
    breadcrumbs: true,
    searching: true,
    css: `
      flex-direction: row;
      & > .products-content {
      }
    `
  },

  "Templates/ArticleListingPage": {
    layoutType: "list",
    itemLimit: 4,
    breadcrumbs: true,
    css: ``
  },

  "Components/ProductGridItem": {
    showAveragePriceWhenBuyXGetYFree: true,
    themeColor: "brown",
    stars: 5
  }
};

module.exports = AppConfig;
