export const SupportLangs = [
  { display: "繁體中文", key: "tw" },
  { display: "English", key: "en" }
];

export const DefaultLang = "en";

const StringTable = {
  tw: {
    home: "首頁",
    productsAndServices: "產品與服務",
    products: "產品",
    services: "服務",
    aboutUs: "關於我們",
    contactUs: "聯絡我們",

    homeTitle: "全球領先的替代漫遊服務提供者",
    homeSubtitle:
      "我們的產品包括可負擔的全球移動連接和B2C，B2B和IoT連接的管理解決方案。您可以在超過210個國家當中找到350多個與我們合作的夥伴。",
    howOperate: "如何運作",
    howOperateContent:
      "和TEL-U合作，您可以輕鬆造訪超過100多個國家/地區的網路。 通過出色及穩定的連接性，這可以幫助用戶有良好的使用體驗。 M2M的連接解決了管理多個不共享定價規則或協議條款的運營商的挑戰。",
    whyM2m: "為什麼要使用M2M",
    whyM2mContent:
      "為什麼要選擇M2M的解決方案？原因很簡單。M2M和物聯網正在改變我們的生活方式。科技讓我們可以將所有的東西都連接到互聯網上，在未來幾年內，將有500多億台設備會連接起來。通過TEL-U解決方案，您可以獲得最安全、最可靠的網路提供商，支援M2M和IoT應用程式，並允許人們和企業信任我們所提供的互聯網世界。",
    clientOpinion: "我們的客戶怎麼說",
    clientOpinionContent:
      "我們和TEL-U合作了滿長一段時間。我們得到的支援總是極佳且即時的。我們極力推薦TEL-U給各個需要可靠M2M供應商及M2M設備的公司",

    productsSubtitle:
      "TEL-U提供快速、安全和強大的全球物聯網網路，可供給您各項物聯網的需求。 M2M（Machine-to-Machine）通信是電子和機械設備之間進行的交流，無需人工介入的資料傳輸和測量。我們知道每個專案都有不同的需求而且沒有一個設備是相同的。因此，我們的M2M解決方案支持各種網路標準及型號，所以僅需一家伺服器供應商，它就能快速將您的IoT設備與150多個國家的多個移動網路相互串聯。它同樣能夠讓您選擇在特定國家/地區造訪訊號最佳的網路。",
    advantage: "優勢",
    advantageContent:
      "作為TEL-U的合作夥伴，只需要一家供應商即能滿足您在全球的M2M 、SIM卡需求。快速、簡單、有效率，將使您的核心業務或客戶更有價值。 保證讓您擁有最佳的全球覆蓋範圍。",
    service: "服務內容",
    serviceContent:
      "我們的國際移動漫遊商品SIM TravelSim於2017年推出，是一項極具突破性的產品，不僅擁有超過500萬名的用戶，並在全球60多個國家銷售。如今我們在世界各地供應以TravelSim，Gosim和Travelchat為商標的產品。",

    aboutUsSubtitle:
      "TEL-U成立於2017年，其初衷是相信物聯網的影響將徹底改變全球商業並通過智 能設備改善我們的生活。TEL-U使我們的客戶能夠在全球範圍內有效率的連接，管理，控制IoT和M2M服務 。我們公司總部位於香港，由一群年輕，充滿活力和熱情的專業人士所組成。",
    ourTech: "我們的技術",
    ourTechContent:
      "我們的平台專門提供IoT和M2M設備的連接性。 TEL-U創始團隊看到了全球對一個可靠、擴展性強、且靈活的連接管理平台的需求，同時可以為客戶提供全方 位的移動網絡運營商功能。我們基於雲端服務提供連接和虛擬蜂窩平台，與傳 統的移動運營商相比，該平台為客戶提供了更多的工具和資源。我們在市場上 獨有的技術能滿足企業，系統集成商和CSP的廣泛需求。",
    contactUsContent:
      "如果有任何問題或是您想更進一步的瞭解我們，歡迎聯繫我們 將有專人為您服務",
    whyMDC: "為什麼選擇TEL-U?",
    whyMDCContent:
      "我們的團隊與全球多個組織合作，並且在與數字化轉型、物聯網、基礎設施管理，酒店，M2M，MVNO / E和企業移動性等最新技術合作領域上展示出極大的潛力。集結多年的經驗，TEL-U開發出一套獨家的技術，可應用於任何的市場上，以利擷取及傳送貴公司的重要數據 我們不僅提供IoT實作所需的主要組件，也提供世界一流的客戶支援。警報和端到端透明性的結合簡化並增強了服務問題的識別、分析和解決。 我們的專業經驗和知識保證可以為客戶提供最佳的服務品質。",
    companyName: "移動資通管理顧問有限公司",
    address: "台北市中山區民權東路二段148號5樓"
  },
  en: {
    home: "home",
    productsAndServices: "Products and Services",
    products: "products",
    services: "services",
    aboutUs: "About us",
    contactUs: "Contact us",

    homeTitle: "The world's leading alternative roaming service provider",
    homeSubtitle:
      "Our products include affordable global mobile connectivity and management solutions for B2C, B2B and IoT connections. You can find more than 350 partners with us in more than 210 countries.",
    howOperate: "How it Works",
    howOperateContent: `With TEL-U you gain easy access to more than 100 networks spread across multiple countries. This helps you provide user-friendly experiences through outstanding connectivity reliability. 
    M2M connectivity addresses the challenges of managing multiple carriers that do not share pricing rules or agreement terms.
    `,
    whyM2m: "Machine-to-Machine solution",
    whyM2mContent: `Why should I get an M2M solution? The reason is simple. M2M and IoT are changing the world in which we live in. Technology allows us to connect virtually everything to the Internet, and within the next few years, more than 50 billion devices will be connected. With a TEL-U solution, you get the most secure and reliable network provider that enable M2M and IoT app applications and allow people and enterprises to trust in our connected world.`,
    clientOpinion: "WHAT OUR CUSTOMERS SAY",
    clientOpinionContent: `We have been working with TEL-U for a while. The support we have received has always been excellent and timely. We would recommend TEL-U to any company who needs a reliable M2M provider for their application or M2M device.`,

    productsSubtitle: `TEL-U provides quick, secure and a robust worldwide IoT network availability to help your IoT business needs. M2M (Machine-to-Machine) communication is data transmission and measurement between electronic and mechanical devices without human intervention. We know that every project has different needs and that no device is the same. Therefore our M2M solution supports various cellular standards and form factors. It quickly associates your IoT devices to multiple mobile networks in more than 150 nations with just one service provider. It likewise empowers you to pick the best signal network accessible in a particular country. `,
    advantage: "Advantage",
    advantageContent: `As a partner with TEL-U, you get one provider to fulfill all your global M2M SIM-card needs. Fast, easy, and efficient, adding value to your core business or your customers.
    This guarantees you have the best worldwide coverage consistently.
    `,

    service: "Service Content",
    serviceContent:
      "Launched in 2017, our international mobile roaming product SIM TravelSim is a breakthrough product that not only has more than 5 million users, but also sells in more than 60 countries worldwide. Today we supply products under the trademark TravelSim, Gosim and Travelchat around the world.",

    aboutUsSubtitle: `TEL-U was founded in 2017, born out of a belief that the impact of IoT will revolutionize commerce worldwide and improve our lives with intelligent devices.
    TEL-U empower our customers to efficiently and effectively connect, manage, control IoT and M2M services on a global scale. 
The company is headquartered in Hong Kong , backed by a team of young, energetic and passionate professionals.`,
    ourTech: "Our Technology",
    ourTechContent:
      "Our platform was built specifically to provide connectivity for IoT & M2M devices. TEL-U founding team saw the need for a global connectivity management platform that is reliable, scalable and flexible while providing a full range of mobile network operator features to the customer. Our cloud-based services offer on-demand connectivity and a virtualized cellular platform that offers more tools and resources to the customer than traditional mobile operator. We are uniquely positioned to meet the wide-ranging needs of enterprises, system integrators and CSPs.",
    contactUsContent:
      "If you want to know more details, or have any further questions , please contact a member of our team.",
    whyMDC: "Why TEL-U",
    whyMDCContent: `Our people have worked with multiple organizations across the globe and also showing more potential on working with latest technologies in Digital Transformation, IoT, Infrastructure Management, Hospitality, M2M, MVNO/E and Enterprise Mobility. With a combined experience exceeding plenty of years, TEL-U has developed a unique set of principles that can be applied to virtually any application in any market, to capture and deliver the data that's important to your business
    We deliver all the components which are required for a successful IoT implementation, we also delivers world-class customer support. The combination of alerts and end-to-end transparency simplifies and enhances the identification, analysis, and resolution of services issues.
    
    our profession experience and knowledge can guarantee giving our customer’s best quality of services.
    `,
    companyName: "YI TUNG TZU TUNG MANAGEMENT CONSULTANCY CO., LTD.",
    address:
      "5F, No. 148, Section 2, Minquan East Road, Zhongshan District, Taipei City"
  }
};

export default StringTable;
