import React, { Component } from "react";
import styled from "styled-components";
import * as ReactScroll from "react-scroll";

class GeneralItem extends Component {
  render() {
    let {
      img = "",
      title,
      description,
      reverse,
      itemName,
      toElementName,
      button
    } = this.props;

    return (
      <Wrapper>
        <ReactScroll.Element name={itemName}>
          <Container>
            <ImageWrapper>
              <Image img={img} />
            </ImageWrapper>

            <Content reverse={reverse}>
              <h2>{title}</h2>
              <p>{description}</p>
              {button && (
                <ReactScroll.Link
                  to={toElementName}
                  smooth={true}
                  offset={-100}
                >
                  <Button
                    css={`
                      margin-top: 30px;
                    `}
                  >
                    Learn More
                  </Button>
                </ReactScroll.Link>
              )}
            </Content>
          </Container>
        </ReactScroll.Element>
      </Wrapper>
    );
  }
}

let Wrapper = styled.div`
  background-color: black;
`;

let Container = styled.div`
  display: flex;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

let ImageWrapper = styled.div`
  flex: 1 1 82px;
  height: 460px;
`;

let Image = styled.div`
  flex: 1 1 82px;
  height: 460px;
  background-color: #ccc;
  background-image: url('${props => props.img}');
  background-size: cover;
  background-position: center;

`;

let Content = styled.div`
  flex: 1;
  padding: 42px;
  order: ${props => (props.reverse ? -1 : 0)};
  & > h2 {
    color: #418db9;
    font-size: 40px;
    letter-spacing: 1px;
    margin-bottom: 30px;
  }

  & > p {
    color: #fff;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 33px;
  }

  @media screen and (max-width: 700px) {
    order: 0;
  }
`;

let Button = styled.div`
  display: flex;
  background: linear-gradient(to right, #0d3369, #418db9);
  width: 200px;
  height: 50px;
  color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  cursor: pointer;

  ${props => props.css}
`;

export default GeneralItem;
